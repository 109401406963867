import React from "react";
import { withRouter } from "react-router-dom";
import { getCurrentClient,logout } from "../services/auth.service";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
const verifyCurrentUser = () => {
  const currentUser = getCurrentClient();
  if (currentUser) {
    const decodedJwt = parseJwt(currentUser.accessToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      logout();
    }
  }
  return <></>;
};
export default withRouter(verifyCurrentUser);
