import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../common/css/styles.css"
import { ReactComponent as MutLogo } from "../../common/images/mut_logo.svg";
import HeaderPage from "../HeaderPage/HeaderPage";

const FailedWalletPayment = (props) => {
    const History = useHistory();
    const [origin_payment, setOriginPayment] = useState(undefined)

    useEffect(() => {
        function getOriginPayment() {
            if (props.paymentResponse) {
                setOriginPayment(props.paymentResponse.objeto_pago.id_comprobante_pago.split('-')[0])
            }
        }
        getOriginPayment()
    }, []);


    return (
        <div>
            <div>
                <HeaderPage />
                <div className="flex pl-12">
                        <label className="back-icon" style={{textAlign:"left"}} onClick={() => History.push("/home")}>←
                        <label class="mut-text-h1 pl-20">PAGO FALLIDO</label>
                        </label>
                </div>
                <div>

                    {origin_payment === 'PP' ?
                        <label className="mut-text-h2" style={{ marginBottom: "2em" }} >
                            No se pudo realizar la transacción, <a href={`${window.location.origin}/planes`} >inténtelo nuevamente</a>
                        </label>

                        :

                        <label className="mut-text-h2" style={{ marginBottom: "2em" }} >
                            No se pudo realizar la transacción, <a href={`${window.location.origin}/wallet`} >inténtelo nuevamente</a>
                        </label>
                    }


                </div>
            </div>
        </div>
    );
};

export default FailedWalletPayment;