import axios from "axios";
import Url from "../url";


const WebpayPlus = () => {
    const createTransaction = (origen_pago, cliente, amount) => {
        return new Promise((resolve, reject) => {
            const cliente_aux = { ...cliente }
            delete cliente_aux.id
            delete cliente_aux.accessToken

            const headers = {
                "content-type": 'application/json',
                "x-access-token": cliente.accessToken
            }

            axios
                .post(
                    Url + "/api/webpay_plus/create", { amount: amount, idCliente: cliente.id, origin_payment: origen_pago }, { headers: headers }
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    const createTransactionPlan = (origen_pago, cliente, obj_plan, cupon_id) => {
        return new Promise((resolve, reject) => {
            const cliente_aux = { ...cliente }
            delete cliente_aux.id
            delete cliente_aux.accessToken

            const headers = {
                "content-type": 'application/json',
                "x-access-token": cliente.accessToken
            }

            console.log(origen_pago)
            console.log(cliente)
            console.log(obj_plan)

            axios
                .post(
                    Url + "/api/webpay_plus/create", {
                    amount: obj_plan.precio,
                    idCliente: cliente.id,
                    origin_payment: origen_pago,
                    cupon_id: cupon_id,
                    obj_plan: obj_plan
                }, { headers: headers }
                )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    const validateTransaction = (cliente, token_ws, token_tbk, tbk_id_sesion, buyOrder) => {
        return new Promise((resolve, reject) => {
            const cliente_aux = { ...cliente }
            delete cliente_aux.id
            delete cliente_aux.accessToken

            const headers = {
                "content-type": 'application/json',
                "x-access-token": cliente.accessToken
            }

            axios
                .post(Url + "/api/webpay_plus/commit", {
                    buyOrder: buyOrder,
                    token_ws: token_ws,
                    token_tbk: token_tbk,
                    tbk_id_sesion: tbk_id_sesion
                }, { headers: headers })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    return {
        createTransaction,
        createTransactionPlan,
        validateTransaction
    };
}

export default WebpayPlus;
