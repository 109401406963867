import React,{useEffect,useState} from "react";
//import axios from "axios"
import { useHistory } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../../common/images/phone_icon_red.svg";
import { ReactComponent as MailIcon } from "../../common/images/mail_icon_red.svg";
import { ReactComponent as AddressIcon } from "../../common/images/address_icon_red.svg";
import iconProfile from "../../common/images/profile_icon_white.svg";
import "./Perfil.css"
import { getCurrentClient } from "../../services/auth.service"
//import Url from "../../url";
import Constantes from "../../common/constantes"
import HeaderPage from "../HeaderPage/HeaderPage";

const Perfil = (props) => {
    let History = useHistory();
    const cliente = getCurrentClient()
    const [address,setAddress] = useState("")
    let name = `${cliente.nombres} ${cliente.apellidoPaterno}`
    name = name.toUpperCase()

    useEffect(() => {
        function getComunas() {
            const comuna = Constantes.comunas[cliente.idComuna-1][1]
            setAddress(`${cliente.direccion}, ${comuna}`)
        }
        getComunas()  
    }, []);

    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                <HeaderPage />
                <div className="flex pl-12">
                    <label className="back-icon" style={{textAlign:"left"}} onClick={() => History.goBack()}>←</label>
                </div>
                <div>
                    <div className="grid grid-rows-1 justify-items-center gap-4 pb-12">
                        <div className="circle-perfil">
                            <img src={iconProfile} alt="icon" className="icon-circle-perfil h-48 fill-white" />  
                        </div>
                    </div>
                    <div className="grid grid-rows-2 justify-items-center gap-4">
                        <label className="mut-text-h2 text-3xl lg:text-5xl">{name}</label>
                        <label className="mut-text-h2 text-3xl lg:text-5xl">{cliente.rut}</label>
                    </div>
                </div>
                <div>
                    <div className="flex pl-28 pt-20 pr-20 gap-4">
                        <PhoneIcon className="icon-style"/>
                        <label className="mut-text-h5 text-black text-2xl lg:text-3xl pt-5" style={{textAlign:"left"}} >{cliente.fono}</label>
                    </div>
                    <div className="flex pl-28 pr-20 gap-4 pt-12">
                        <MailIcon className="icon-style"/>
                        <label className="mut-text-h5 text-black text-2xl lg:text-3xl pt-3" style={{textAlign:"left"}}>{cliente.mail}</label>
                    </div>
                    <div className="flex pl-28 pr-20 gap-4 pt-12">
                        <AddressIcon className="icon-style"/>
                        <label className="mut-text-h5 text-black text-2xl lg:text-3xl pt-5" style={{textAlign:"left"}}>{address}</label>
                    </div>
                </div>
                <div className="pb-20 pt-20">
                    <input 
                        id="btn-cerrar-sesion" 
                        type="button" 
                        className="fadeIn third capitalize bg-green-mut text-3xl lg:5xl" 
                        value="Editar datos"
                        onClick = {()=> History.push("/editar_perfil")} 
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Perfil;