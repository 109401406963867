import React, { useEffect, useState } from "react";
//import axios from "axios"
import { useHistory } from "react-router-dom";
import { ReactComponent as WalletIcon } from "../../common/images/wallet_icon_red.svg";
import { ReactComponent as WebPay } from "../../common/images/web_pay.svg";
import "./Wallet.css";
import { getCurrentClient } from "../../services/auth.service";
import Form from "react-validation/build/form";
import Url from "../../url";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import WebpayPlus from "../../services/WebpayPlus";
import axios from "axios";
import HeaderPage from "../HeaderPage/HeaderPage";
import PerroMut from "../../common/images/perro_mut.png";

const Wallet = (prop) => {
  let History = useHistory();
  const [cliente, setCliente] = useState(getCurrentClient());
  const [current_saldo, setCurrentSaldo] = useState(0);
  const [new_saldo, setNewSaldo] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [pressedButton, setPressedButton] = useState(false);
  const WebpayPlusService = WebpayPlus();

  useEffect(() => {
    async function getClientData() {
      const headers = {
        "x-access-token": cliente.accessToken,
      };
      return axios
        .get(`${Url}/api/fromPage/cliente/data/${cliente.id}`, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
          if (
            response.data.cliente.saldo === null ||
            response.data.cliente.saldo === undefined
          ) {
            setCurrentSaldo(0);
          } else {
            setCurrentSaldo(response.data.cliente.saldo);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getClientData();
  }, []);

  const handleInputChangeNewSaldo = (event) => {
    setNewSaldo(event.target.value);
  };

  let cliente_saldo = current_saldo.toLocaleString().replaceAll(",", ".");
  let saldo = `$${cliente_saldo}`;
  //let saldo = 0

  function redirectToWebpay(data) {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = data.webpay_data.url;
    const tokenInput = document.createElement("input");
    tokenInput.type = "hidden";
    tokenInput.name = "token_ws";
    tokenInput.value = data.webpay_data.token;
    form.appendChild(tokenInput);
    // Append the form to the document
    document.body.appendChild(form);
    // Submit the form to redirect
    form.submit();
  }

  function chargeAmount() {
    setPressedButton(true);
    WebpayPlusService.createTransaction("wallet", cliente, new_saldo)
      .then((response) => {
        console.log(response.data);
        redirectToWebpay(response.data);
      })
      .catch((error) => {
        setPressedButton(true);
        console.log(error);
      });
  }

  const displayAlert = (showAlert, message) => {
    setAlertMessage(message);
    setShowAlert(showAlert);
  };

  function validationTextfields() {
    console.log(typeof new_saldo);
    if (selectedValue === "") {
      displayAlert(true, "Debe ingresar un medio de pago");
    } else if (!new_saldo.toString().trim().length) {
      displayAlert(true, "Debe ingresar un monto");
    } else if (isNaN(new_saldo)) {
      displayAlert(true, "Debe ser un número válido");
    } else if (new_saldo < 1000) {
      displayAlert(true, "El monto debe ser mayor $1.000 y menor a $90.000");
    } else if (new_saldo > 90000) {
      displayAlert(true, "El monto debe ser mayor $1.000 y menor a $90.000");
    } else {
      displayAlert(false, "");
      chargeAmount();
    }
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <HeaderPage />
          <div className="flex pl-12">
            <label
              className="back-icon"
              style={{ textAlign: "left" }}
              onClick={() => History.goBack()}
            >
              ←<label className="mut-text-h2 text-5xl pl-16 lg:pl-36">WALLET</label>
            </label>
          </div>
          <div>
            <WalletIcon
              className="icon-inside-card pl-16 lg:pl-20 pb-10"
              style={{ width: "60%" }}
            />
            <div>
              <label className="mut-text-h2 text-5xl lg:text-7xl pb-20">{saldo}</label>
            </div>
          </div>
          <Form>
            <div class="inputDiv">
              <input
                type="text"
                className="fadeIn second bg-pink-mut placeholder-pink rounded-2xl"
                name="new_saldo"
                placeholder="Escribe aquí un monto a cargar"
                value={new_saldo}
                onChange={handleInputChangeNewSaldo}
              />
            </div>
          </Form>
          <div>
            <div style={{ textAlign: "left" }}>
              <label className="mut-text-h2 text-3xl lg:text-4xl pl-12 pt-20 pb-10">
                Medio de pago:
              </label>
            </div>
          </div>
          <div className="div-form-radio-button pl-8 lg:pl-0">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <div className="card-radio-button">
                  <FormControlLabel
                    value="webpay"
                    control={
                      <Radio
                        style={{ top: "-25px" }}
                        size="large"
                        sx={{
                          color: "#375480",
                          "&.Mui-checked": {
                            color: "#375480",
                          },
                        }}
                      />
                    }
                    label={<WebPay className="icon-inside-card-pago" />}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="pt-16 lg:pb-20 pt-20">
            <button
              type="button-wallet"
              className="fadeIn third capitalize bg-green-mut text-3xl lg:5xl"
              onClick={() => validationTextfields()}
              disabled={pressedButton}
            >
              {pressedButton ? <CircularProgress color="inherit" /> : "Cargar"}
            </button>
            {alertMessage && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {alertMessage}
                </div>
              </div>
            )}
          </div>
          <div className="mt-24 pt-16 lg:pt-20">
            <img
              src={PerroMut}
              alt="perro mut"
              style={{ marginBottom: "-25rem" }}
            />
          </div>
          <div>
            <label
              className="mut-text-h2 text-3xl lg:text-4xl pl-12 pr-60"
              style={{ textAlign: "left" }}
            >
              Nosotros cuidamos tu bici, tu cleta tu chancha.
            </label>
          </div>
          <div className="mb-64"> </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
