import React from "react";
import { useHistory } from "react-router-dom";
import iconProfile from "../../common/images/profile_icon_white.svg";
import bikeIcon from "../../common/images/bike_icon_white.svg";
import walletIcon from  "../../common/images/wallet_icon_white.svg";
import planIcon from "../../common/images/plan_icon_white.svg";
import qrIcon from "../../common/images/qrcodescan.png"
import "./Home.css"
import { logout } from "../../services/auth.service"
import HeaderPage from "../HeaderPage/HeaderPage";

const Home = (props) => {
  let History = useHistory();

  function logOut() {
    logout()
    History.push("/inicio")
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
        <HeaderPage />
        <div className="place-content-center text-center p-20">
            <label className="mut-text-h2 text-5xl">
            ¡Disfruta de tu aventura sobre ruedas!
            </label>
          </div>
          <div className="grid grid-cols-2 place-content-center gap-4">
            <div className="home-single-card" onClick={() => History.push("/perfil")}>
            <div className="grid grid-rows-2 place-content-center gap-4">
              <div className="circle">
                <img src={iconProfile} alt="icon" className="icon-circle h-24 fill-white" />  
              </div>
              <label className="text-red-mut mut-text-h4 text-3xl" >Perfil</label>
            </div>
            </div>
            <div className="home-single-card" onClick={()=>History.push("/bicicleta")}>
              <div className="grid grid-rows-2 place-content-center gap-4">
                <div className="circle">
                  <img src={bikeIcon} alt="icon" className="icon-bike h-20" />
                </div>
                <label className="text-red-mut mut-text-h4 text-3xl" >Mi Bici</label>
              </div>
            </div>
            <div className="home-single-card" onClick={()=>History.push("/wallet")}>
              <div className="grid grid-rows-2 place-content-center gap-4">
                <div className="circle pb-6 pl-6">
                  <img src={walletIcon} alt="icon" className="icon-wallet h-24" />  
                </div>
                <label className="text-red-mut mut-text-h4 text-3xl">Billetera</label>
              </div>
            </div>
            <div className="home-single-card" onClick={() => History.push("/planes")}>
              <div className="grid grid-rows-2 place-content-center gap-4">
                <div className="circle">
                  <img src={planIcon} alt="icon" className="icon-plan h-20" />  
                </div>
                <label className="text-red-mut mut-text-h4 text-3xl">Planes</label>
              </div>
            </div>
            <div className="home-single-card" onClick={() => History.push("/qrCode")}>
              <div className="grid grid-rows-2 place-content-center gap-4">
                <div className="circle">
                  <img src={qrIcon} alt="icon" className="qr-circle h-24" style={{ filter: 'invert(1)' }}/>  
                </div>
                <label className="text-red-mut mut-text-h4 text-3xl">Código QR</label>
              </div>
            </div>
          </div>
          <div className="pb-20 pt-20">
            <input
              id="btn-cerrar-sesion"
              type="button"
              className="fadeIn third capitalize bg-green-mut text-3xl lg:text-5xl"
              value="Cerrar sesión"
              onClick={() => logOut()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
