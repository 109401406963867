import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../common/css/styles.css"
import WebpayPlus from "../../services/WebpayPlus";
import { getCurrentClient } from "../../services/auth.service"
import SuccessfulPayment from "./SuccessfulPayment";
import FailedPayment from "./FailedPayment";
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as MutLogo } from "../../common/images/mut_logo.svg";
import HeaderPage from "../HeaderPage/HeaderPage";



const ProccessingPayment = (props) => {

    const WebpayPlusService = WebpayPlus();
    const cliente = getCurrentClient()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [TOKEN_WS] = useState(params.get('token_ws'))
    const [TOKEN_TKB] = useState(params.get('TBK_TOKEN'))
    const [TBK_ID_SESION] = useState(params.get('TBK_ID_SESION'))
    const [buyOrder] = useState(params.get('TBK_ORDEN_COMPRA'))

    const [proccessingPayment, setProccessingPayment] = useState(true)
    const [payment_state, setPaymentState] = useState("")
    const [payment_response, setPaymentResponse] = useState("")

    //3 Casos
    // 1. Cuando el pago es existoso y recibe un token_ws y hay que verificar si efectivamente fue exitoso
    // 2. Cuando el pago es fallido y recibe un token_ws y hay que verificar si efectivamente fue fallido
    // 3. Cuando el pago es anulado o se demoro mucho la persona, se recibe el TOKEN_TKB, ID-ORDER y ID-SESION

    useEffect(() => {
        //console.log("TOKEN_WS:", TOKEN_WS)
        //console.log("TOKEN_TKB:", TOKEN_TKB)
        //console.log("TBK_ID_SESION:", TBK_ID_SESION)
        //console.log("buyOrder:", buyOrder)

        WebpayPlusService.validateTransaction(cliente, TOKEN_WS, TOKEN_TKB, TBK_ID_SESION, buyOrder)
            .then((response) => {
                //console.log(response.data)
                setProccessingPayment(false)
                setPaymentState(response.data.objeto_pago.estado_pago)
                setPaymentResponse(response.data)
            }).catch((error) => {
                setPaymentState('invalido')
                //console.log('ERROR en Pago')
                console.log(error)
            });
    }, []);

    function showViewResultPayment() {
        if (payment_state === 'pendiente' ||
            payment_state === 'cancelado' ||
            payment_state === 'anulado' ||
            payment_state === 'rechazado' ||
            payment_state === 'invalido') {
            return (<FailedPayment paymentResponse={payment_response} />)
        } else if (payment_state === 'aprobado') {
            return (<SuccessfulPayment paymentResponse={payment_response} />)
        }
    }

    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    {proccessingPayment ?
                        <div style={{ marginBottom: '3em' }}>
                            <HeaderPage />
                            <label
                                className="mut-text-h2"
                                style={{ marginBottom: "2em" }}
                            >
                                Estamos procesando el pago
                            </label>
                            <br></br>
                            <CircularProgress color="inherit" />
                        </div>
                        :
                        <>{showViewResultPayment()}</>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProccessingPayment;
