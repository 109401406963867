import React from "react";
import { useHistory } from "react-router-dom";
import "../../common/css/styles.css"
import { ReactComponent as MutLogo } from "../../common/images/mut_logo.svg";
import { getCurrentClient } from "../../services/auth.service"
import HeaderPage from "../HeaderPage/HeaderPage";

const SuccessWalletPayment = (props) => {
    const History = useHistory();
    const cliente = getCurrentClient()
    return (
        <div>
            <div>
                <HeaderPage />
                <div className="flex pl-12">
                        <label className="back-icon" style={{textAlign:"left"}} onClick={() => History.push("/home")}>←
                        <label className="mut-text-h1 pl-20">PAGO EXITOSO</label>
                        </label>
                </div>
                
                <div>
                    <label
                        className="mut-text-h2" style={{ marginBottom: "2em" }}
                    >
                        Se mando el comprobante de carga a su correo <span style={{ color: "#92badd" }}>{cliente.mail}</span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default SuccessWalletPayment;
