import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div>
    <h1>Error 404 - Lo sentimos, la Url no fue encontrada!</h1>
    <Link to="/inicio" >Ir a Inicio</Link>
  </div>
);

export default NotFound;
