import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";
import Inicio from "./components/Inicio/Inicio";
import Login from "./components/Login/Login";
import Mensaje from "./components/Mensaje/Mensaje";
import Register from "./components/Register/Register";
import Home from "./components/Home/Home";
import Perfil from "./components/Perfil/Perfil";
import Bicicleta from "./components/Bicicleta/Bicicleta";
import EditarPerfil from "./components/Perfil/EditarPerfil";
import AuthVerify from "./common/AuthVerify";
import NotFound from "./components/NotFound";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword"
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Planes from "./components/Planes/Planes"
import Wallet from "./components/Wallet/Wallet";
import ProccessingPayment from "./components/Payment/ProcessingPayment";
import  Qr from "./components/Qr/Qr"

const App = () => {
  return (
    <div id="viewport" className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Inicio} />
          <Route path="/inicio" component={Inicio} />
          <Route path="/login" component={Login} />
          <Route path="/registro" component={Register} />
          <Route path="/mensaje" component={Mensaje} />
          <Route exact path="/forgetPassword" component={ForgetPassword} />
          <PrivateRoute exact path="/resetPassword" component={ResetPassword} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/perfil" component={Perfil} />
          <PrivateRoute exact path="/bicicleta" component={Bicicleta} />
          <PrivateRoute exact path="/editar_perfil" component={EditarPerfil} />
          <PrivateRoute exact path="/planes" component={Planes} />
          <PrivateRoute exact path="/wallet" component={Wallet} />
          <PrivateRoute exact path="/processingPayment" component={ProccessingPayment} />
          <PrivateRoute exact path="/qrCode" component={Qr}/>
          <Route path="*" component={NotFound} />
        </Switch>
        <AuthVerify />
      </BrowserRouter>
    </div>
  );
};

export default App;
