import React, { /*useEffect,*/ useState } from "react";
import axios from "axios";
import Url from "../../url";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ReactComponent as MutLogo } from "../../common/images/mut_logo.svg";
import { useHistory } from "react-router-dom";
import "../Register/Register"
import { getCurrentClient } from "../../services/auth.service";

const passwordValidator = require("password-validator");

const schema = new passwordValidator();

schema
    .is()
    .min(6) // Minimum length 6
    .is()
    .max(100) // Maximum length 100
    //.has()
    //.uppercase() // Must have uppercase letters
    //.has()
    //.lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces(); // Should not have spaces

const ResetPassword = (props) => {
    const history = useHistory();
    // eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [cliente, setCliente] = useState(getCurrentClient)
    const [newPassword, setNewPassword] = useState("");
    const [confirm_new_password, setConfirmNewPassword] = useState("");

    const handleSetConfirmPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setNewPassword(event.target.value);
    };


    const displayAlert = (showAlert, message) => {
        setAlertMessage(message);
        setShowAlert(showAlert);
    };

    function validationTextfields() {
        if (!schema.validate(newPassword)) {
            displayAlert(
                true,
                "Ingrese una contraseña válida: Debe contener al menos 6 caracteres, dos números y no debe contener espacios."
            );
        }
        else if (newPassword !== confirm_new_password) {
            displayAlert(true, "Las contraseñas ingresadas no coinciden");
        }
        else {
            displayAlert(false, "");
            submitNewPasswordCliente();
        }
    }

    function submitNewPasswordCliente() {
        const headers = {
            "content-type": 'application/json',
            "x-access-token": cliente.accessToken
        }

        axios
            .put(`${Url}/api/fromPage/cliente/resetPassword/${cliente.id}`, { password: newPassword }, { headers: headers })
            .then(() => {
                alert('Contraseña actualizada con exito')
                history.push("/home");
            })
            .catch((error) => {
                console.error(
                    "Error:" + JSON.parse(error.request.response).message
                );
                displayAlert(
                    true,
                    "Error: " + JSON.parse(error.request.response).message
                );
            });
    }

    return (
        <div style={{ backgroundColor: "#e6cfc8" }}>
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <div className="fadeIn first">
                        <div>
                            <MutLogo class="mut-icon-second" style={{marginTop:"3em"}} />
                            <div>
                                <label class="mut-text-h1">Actualizar contraseña</label>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <Form>
                        <div class="inputDiv">
                            <Input
                                type="search"
                                className="fadeIn fourth"
                                value="Contraseña"
                                disabled
                            />
                            <Input
                                type="password"
                                id="passwordRegister"
                                className="fadeIn fourth"
                                name="password"
                                placeholder="Contraseña"
                                value={newPassword}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <br></br>
                        <div class="inputDiv">
                            <Input
                                type="search"
                                className="fadeIn fifth"
                                value="Confirmar contraseña"
                                disabled
                            />
                            <Input
                                type="password"
                                id="confirm_new_password"
                                className="fadeIn fifth"
                                name="confirm_new_password"
                                placeholder="Ingrese nuevamente su contraseña"
                                value={confirm_new_password}
                                onChange={handleSetConfirmPasswordChange}
                            />
                        </div>
                        <br></br>
                        <Input
                            type="button"
                            className="fadeIn fourteenth text-3xl lg:text-5xl" 
                            value="Actualizar"
                            onClick={() => validationTextfields()}
                            style={{width: "85%"}}
                        />
                        {alertMessage && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {alertMessage}
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
