import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentClient } from "../../services/auth.service";
import QRCode from "qrcode.react";
import "./Qr.css";
import HeaderPage from "../HeaderPage/HeaderPage";
import IconoLogin from "../../common/icons/icono_login.png";

const Qr = (prop) => {
  let History = useHistory();
  const [cliente, setCliente] = useState(getCurrentClient());
  const [text, setText] = useState(
    "https://portal.sidiv.registrocivil.cl/docstatus?RUN=" + cliente.rut
  );

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <HeaderPage />
          <div className="flex pl-12 pb-20">
            <label
              className="back-icon"
              style={{ textAlign: "left" }}
              onClick={() => History.goBack()}
            >
              ←
            </label>
          </div>
          <div className="qr-code-container">
            <QRCode value={text} size={255} />
          </div>
          <div className="mt-24 mb-20 pt-20">
            <img src={IconoLogin} alt="icono login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qr;
