import React, { useState } from "react";
import Form from "react-validation/build/form";
import { login } from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import "../../common/css/styles.css";
import "./Login.css";
import HeaderPage from "../HeaderPage/HeaderPage";
import IconoLogin from "../../common/icons/icono_login.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido
      </div>
    );
  }
};

const Login = (props) => {
  let History = useHistory();
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const onChangeMail = (e) => {
    const mail = e.target.value;
    setMail(mail.toLowerCase());
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    login(mail, password).then(
      (data) => {
        if (data.resetPassword) {
          History.push("/resetPassword");
        } else {
          History.push("/home");
        }
        //window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <div>
            {/* <div className="header-group">
              <label className="back-icon" onClick={() => History.goBack()}>
                ←
              </label>
              
              <div className="header-fill"></div>
            </div> */}
            <HeaderPage />
            <div>
              <label className="mut-text-h1">INICIA SESIÓN</label>
            </div>
          </div>
          <div>
            <Form onSubmit={handleLogin} className="grid">
              <div className="mb-5 ">
                <input
                  type="text"
                  className="fadeIn second bg-pink-mut placeholder-black rounded-2xl"
                  name="mail"
                  placeholder="Correo"
                  value={mail}
                  onChange={onChangeMail}
                  validations={[required]}
                />
                <input
                  type="password"
                  className="fadeIn third bg-pink-mut placeholder-black"
                  name="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </div>
              <div>
                <a href="/forgetPassword" className="mut-text-h3 mb-10">
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
              <div>
                <input
                  type="submit"
                  className="fadeIn fourth bg-red-mut w-5/6 mb-10 text-3xl lg:text-5xl"
                  value="Entrar"
                />
              </div>
              {message && (
                <div className="form-group mt-5">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <div>
                <label className="mut-text-h3">
                  Si no tienes cuenta,{" "}
                  <label className="mut-text-h3">
                    <a
                      className="text-red-mut underline underline-offset-2"
                      href="/registro"
                    >
                      regístrate aquí
                    </a>
                  </label>
                </label>
              </div>
            </Form>
          </div>
          <div className="mt-24 mb-32">
            <img src={IconoLogin} alt="icono login" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
