import React from "react";
import { useHistory } from "react-router-dom";
import Form from "react-validation/build/form";
import "../../common/css/styles.css";
import HeaderPage from "../HeaderPage/HeaderPage";
import IconoInicio from "../../common/icons/icono_inicio.png";

const Inicio = (props) => {
  let History = useHistory();

  const handleEvent = (e) => {
    if (e.target.name === "iniciar-sesion") {
      History.push("/login");
    } else if (e.target.name === "registrarse") {
      History.push("/registro");
    }
    e.preventDefault();
  };

  return (
    <div>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <HeaderPage />
          <div className="place-content-center text-left p-20">
            <label className="mut-text-h2 text-3xl lg:text-5xl">
              Bienvenido al Bicihub de MUT, movilicémonos en bici, y así
              cuidamos el planeta!
            </label>
          </div>
          <Form className="pb-20">
            <div className="grid pl-20 pr-20 gap gap-4">
              <input
                className="fadeIn second capitalize bg-red-mut text-3xl lg:text-5xl"
                type="button"
                name="iniciar-sesion"
                value="Iniciar Sesion"
                onClick={handleEvent}
              />

              <input
                className="fadeIn third capitalize bg-green-mut text-3xl lg:text-5xl"
                type="button"
                name="registrarse"
                value="Regístrate"
                onClick={handleEvent}
              />
            </div>
          </Form>
          <div className="flex pr-40 pl-40 lg:pr-52 lg:pl-52">
            <img src={IconoInicio} alt="icono inicio" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
