import React from "react";
import { ReactComponent as MutLogo } from "../../common/images/mut_logo.svg";
import { ReactComponent as MujerSentada } from "../../common/images/mujer_sentada.svg";
import { useHistory } from "react-router-dom";
import "../../common/css/styles.css";
import "./Mensaje.css";

const Login = (props) => {
  const history = useHistory();
  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <div>
            <div className="header-group">
              <label className="back-icon" onClick={() => history.push("/")}>
                ←
              </label>
              <MutLogo className="mut-icon-second" />
              <div className="header-fill"></div>
            </div>
            <div>
              <label className="mut-text-h1">¡GRACIAS POR REGISTRARTE!</label>
            </div>
            <br></br>
            <div>
              <label style={{ marginBottom: "-10px" }} className="h1-planes">
                Recuerda, tu constraseña inicial es tu RUT (ej:12345678-9)
              </label>
            </div>
          </div>
          <br></br>
          <div className="div-login-inputs">
            <div className="div-woman-text-footer">
              <div style={{ float: "left" }}>
                <MujerSentada style={{ width: "80%" }} />
              </div>
            </div>
            <div className="div-text-footer">
              <label className="mut-text-h2 label-text-footer">
                ¡Descubre la libertad sobre dos ruedas!
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
