import axios from "axios";
import Url from "../url"
const API_URL = Url+"/api/auth/cliente/";
export const register = (email, password) => {
  return axios.post(API_URL + "signup", {
    email,
    password
  });
};
export const login = (mail, password) => {
  return axios
    .post(API_URL + "signin", {
      mail,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("client", JSON.stringify(response.data));
      }
      return response.data;
    });
};
export const logout = () => {
  localStorage.removeItem("client");
};

export const getCurrentClient = () => {
  return JSON.parse(localStorage.getItem("client"));
};
