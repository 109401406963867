import React from "react";
import BiciHubLogo from "../../common/icons/bicihub_logo.png";
import MutLogo from "../../common/icons/mut_logo.png";

const HeaderPage = (props) => {
  return (
    <div className="flex justify-between h-12 lg:h-16 mt-20 mb-20 pl-12 pr-12">
      <img src={BiciHubLogo} alt="bicihub logo" />
      <img src={MutLogo} alt="mut logo" />
    </div>
  );
};

export default HeaderPage;
