import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../../url";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useHistory } from "react-router-dom";
import "./Register.css";
import Constantes from "../../common/constantes";
import InputMask from "react-input-mask";
import { prettifyRut } from "react-rut-formatter";

import rutValidator from "chilean-rut";
import validator from "email-validator";
import HeaderPage from "../HeaderPage/HeaderPage";

const passwordValidator = require("password-validator");

const schema = new passwordValidator();

schema
  .is()
  .min(6) // Minimum length 6
  .is()
  .max(100) // Maximum length 100
  //.has()
  //.uppercase() // Must have uppercase letters
  //.has()
  //.lowercase() // Must have lowercase letters
  .has()
  .digits(1) // Must have at least 2 digits
  .has()
  .not()
  .spaces(); // Should not have spaces

const Register = (props) => {
  const history = useHistory();
  // eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [comunas, setComunas] = useState([]);
  const [cliente, setCliente] = useState({
    rut: "",
    mail: "",
    password: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    fono: "",
    direccion: "",
    idComuna: 0,
    idGenero: 0,
    activo: false,
  });
  const [confirm_new_password, setConfirmNewPassword] = useState("");

  useEffect(() => {
    function getComunas() {
      const sortedData = Constantes.comunas.sort((a, b) => {
        return a[1].localeCompare(b[1]);
      });
      setComunas(sortedData);
    }
    getComunas();
  }, []);

  const handleSetConfirmPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleInputChangeRut = (event) => {
    setCliente({
      ...cliente,
      [event.target.name]: prettifyRut(event.target.value),
    });
  };

  const handleInputChange = (event) => {
    setCliente({
      ...cliente,
      [event.target.name]: event.target.value,
    });
  };

  const displayAlert = (showAlert, message) => {
    setAlertMessage(message);
    setShowAlert(showAlert);
  };

  function validationTextfields() {
    if (!rutValidator.validate(cliente.rut)) {
      displayAlert(true, "Ingrese un RUT Válido");
    } else if (!validator.validate(cliente.mail)) {
      displayAlert(true, "Ingrese un correo electrónico válido");
    }
    // else if (!schema.validate(cliente.password)) {
    //  displayAlert(
    //     true,
    //     "Ingrese una contraseña válida: Debe contener al menos 6 caracteres, dos números y no debe contener espacios."
    //   );
    // }
    // else if (cliente.password !== confirm_new_password) {
    //  displayAlert(true, "Las contraseñas ingresadas no coinciden");
    // }
    else if (cliente.nombres === "") {
      displayAlert(true, "Ingrese su nombre");
    } else if (cliente.apellidoPaterno === "") {
      displayAlert(true, "Ingrese su primer apellido");
    } else if (cliente.fechaNacimiento === "") {
      displayAlert(true, "Ingrese su fecha de nacimiento");
    } else if (cliente.fono === "") {
      displayAlert(true, "Ingrese su teléfono");
    } else if (cliente.fono.length !== 15) {
      displayAlert(true, "Su teléfono debe incluir 9 dígitos");
    } else if (cliente.idGenero === 0) {
      displayAlert(true, "Seleccione un género");
    }
    // else if (cliente.direccion === "") {
    //   displayAlert(true, "Ingrese su dirección");
    // }
    else if (cliente.idComuna === 0) {
      displayAlert(true, "Seleccione su comuna de residencia");
    } else {
      displayAlert(false, " ");
      submitCreateCliente();
    }
  }

  function submitCreateCliente() {
    let new_cliente = cliente;
    new_cliente.rut = new_cliente.rut.replaceAll(".", "").toUpperCase();
    new_cliente.password = new_cliente.rut;
    new_cliente.mail = new_cliente.mail.toLowerCase();
    axios
      .post(Url + "/api/auth/cliente/signup/", new_cliente)
      .then(() => {
        history.push("/mensaje");
      })
      .catch((error) => {
        console.error("Error:" + JSON.parse(error.request.response).message);
        displayAlert(
          true,
          "Error: " + JSON.parse(error.request.response).message
        );
      });
  }

  return (
    <div>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <HeaderPage />
            <div>
              <div className="header-group">
                <label className="back-icon" onClick={() => history.goBack()}>
                  ←
                </label>
                <div className="mt-12">
                  <label class="mut-text-h1">REGISTRO</label>
                </div>
                <div className="header-fill"></div>
              </div>
            </div>
          </div>
          <br></br>
          <Form>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn second"
                value="RUT (*)"
                disabled
              />
              <Input
                type="text-registro"
                id="rut"
                className="fadeIn second placeholder-pink-mut-placeholder"
                name="rut"
                placeholder="Ingrese su RUT"
                value={cliente.rut}
                onChange={handleInputChangeRut}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn third"
                value="E-mail (*)"
                disabled
              />
              <Input
                type="text-registro"
                id="mail"
                className="fadeIn third placeholder-pink-mut-placeholder"
                name="mail"
                placeholder="ejemplo@mut.cl"
                value={cliente.mail}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            {/*<div class = "inputDiv">
              <Input
                type="search-registro"
                className="fadeIn fourth"
                value="Contraseña"
                disabled
              />
              <Input
                type="password"
                id="passwordRegister"
                className="fadeIn fourth"
                name="password"
                placeholder="Contraseña"
                value={cliente.password}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class = "inputDiv">
              <Input
                type="search-registro"
                className="fadeIn fifth"
                value="Confirmar contraseña"
                disabled
              />
              <Input
                type="password"
                id="confirm_new_password"
                className="fadeIn fifth"
                name="confirm_new_password"
                placeholder="Ingrese nuevamente su contraseña"
                value={confirm_new_password}
                onChange={handleSetConfirmPasswordChange}
              />
            </div>
            <br></br>*/}
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn sixth"
                value="Nombre (*)"
                disabled
              />
              <Input
                type="text-registro"
                id="nombres"
                className="fadeIn sixth placeholder-pink-mut-placeholder"
                name="nombres"
                placeholder="Ingrese su nombre"
                value={cliente.nombres}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn seventh"
                value="Primer apellido (*)"
                disabled
              />
              <Input
                type="text-registro"
                id="apellidoPaterno"
                className="fadeIn seventh placeholder-pink-mut-placeholder"
                name="apellidoPaterno"
                placeholder="Ingrese su primer apellido"
                value={cliente.apellidoPaterno}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn eigth"
                value="Segundo apellido"
                disabled
              />
              <Input
                type="text-registro"
                id="apellidoMaterno"
                className="fadeIn eigth placeholder-pink-mut-placeholder"
                name="apellidoMaterno"
                placeholder="Ingrese su segundo apellido"
                value={cliente.apellidoMaterno}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn ninth"
                value="Fecha de nacimiento (*)"
                disabled
              />
              <Input
                type="date"
                id="fechaNacimiento"
                className="fadeIn ninth placeholder-pink-mut-placeholder"
                name="fechaNacimiento"
                placeholder="Ingrese su fecha de nacimiento"
                value={cliente.fechaNacimiento}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn tenth"
                value="Teléfono (*)"
                disabled
              />
              <InputMask
                mask="+56 9 9999 9999"
                type="text-registro"
                id="fono"
                className="fadeIn tenth placeholder-pink-mut-placeholder"
                name="fono"
                placeholder="Ingrese su número telefónico"
                value={cliente.fono}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn eleventh"
                value="Género (*)"
                disabled
              />
              <select
                required
                className="fadeIn eleventh placeholder-pink-mut-placeholder bg-pink"
                name="idGenero"
                value={cliente.idGenero}
                onChange={handleInputChange}
              >
                <option disabled value={0}>
                  Seleccione su género
                </option>
                <option value={1}>Hombre</option>
                <option value={2}>Mujer</option>
                <option value={3}>Otro</option>
              </select>
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn twelveth placeholder-pink-mut-placeholder"
                value="Dirección"
                disabled
              />
              <Input
                type="text-registro"
                id="direccion"
                className="fadeIn twelveth placeholder-pink-mut-placeholder"
                name="direccion"
                placeholder="Ingrese su dirección"
                value={cliente.direccion}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn thirteenth"
                value="Comuna (*)"
                disabled
              />
              <select
                required
                class="inputSelect"
                className="fadeIn thirteenth placeholder-pink-mut-placeholder"
                name="idComuna"
                value={cliente.idComuna}
                onChange={handleInputChange}
              >
                <option disabled value={0}>
                  Seleccione su comuna
                </option>
                {comunas.sort().map((comuna) => (
                  <option value={comuna[0]}>{comuna[1]}</option>
                ))}
              </select>
            </div>
            <br></br>
            <Input
              type="button"
              className="fadeIn fourteenth capitalize bg-green-mut w-5/6 mb-10 text-3xl lg:text-5xl"
              value="Siguiente"
              onClick={() => validationTextfields()}
              style={{width: "85%"}}
            />
            {alertMessage && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {alertMessage}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
