import React, { useState } from "react";
import Form from "react-validation/build/form";
import { useHistory } from "react-router-dom";
import "../../common/css/styles.css";
import Url from "../../url";
import axios from "axios";
import HeaderPage from "../HeaderPage/HeaderPage";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido
      </div>
    );
  }
};

const ForgetPassword = (props) => {
  let History = useHistory();
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const onChangeMail = (e) => {
    const mail = e.target.value;
    setMail(mail);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .get(`${Url}/api/recuperarPassword/${mail}`)
      .then((response) => {
        alert("Su nueva contraseña ha sido enviada a su correo");
        History.push("/login");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("No hay ningún Usuario asociado al correo indicado");
        } else if (error.response.status === 404) {
          alert("Ocurrio un error Inesperado");
        }
      });
    //console.log(mail)
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <div>
            <HeaderPage />
            <div className="header-group">
              <label className="back-icon" onClick={() => History.goBack()}>
                ←
              </label>
              <div className="header-fill"></div>
            </div>
            <div>
              <label className="mut-text-h1">
                RECUPERAR <br></br> CONSTRASEÑA
              </label>
            </div>
          </div>
          <label className="mut-text-h2 mb-10">
            Ingrese el correo asociado a su usuario de BiciHub
          </label>
          <div className="div-login-inputs mb-20">
            <Form onSubmit={handleLogin}>
              <input
                type="text"
                id="mail-login"
                className="fadeIn second bg-pink-mut"
                name="mail"
                placeholder="Correo"
                value={mail}
                onChange={onChangeMail}
                validations={[required]}
              />
              <div className="mb-5"></div>
              <input
                id="btn-entrar"
                type="submit"
                className="fadeIn fourth bg-green-mut text-3xl lg:text-5xl"
                value="ENVIAR"
                style={{width: "85%"}}
              />
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
