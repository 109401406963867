import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../../url";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import "../Register/Register.css";
import { getCurrentClient } from "../../services/auth.service";
import Constantes from "../../common/constantes";

import rutValidator from "chilean-rut";
import validator from "email-validator";
import HeaderPage from "../HeaderPage/HeaderPage";

const passwordValidator = require("password-validator");
const schema = new passwordValidator();

schema
  .is()
  .min(6) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  //.has()
  //.uppercase() // Must have uppercase letters
  //.has()
  //.lowercase() // Must have lowercase letters
  .has()
  .digits(2) // Must have at least 2 digits
  .has()
  .not()
  .spaces(); // Should not have spaces

const EditarPerfil = (props) => {
  const history = useHistory();
  // eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [comunas, setComunas] = useState([]);
  const [cliente, setCliente] = useState(getCurrentClient);
  const [confirm_new_password, setConfirmNewPassword] = useState("");

  useEffect(() => {
    function getComunas() {
      setComunas(Constantes.comunas);
    }
    getComunas();
  }, []);

  const handleSetConfirmPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleInputChange = (event) => {
    setCliente({
      ...cliente,
      [event.target.name]: event.target.value,
    });
  };

  const displayAlert = (showAlert, message) => {
    setAlertMessage(message);
    setShowAlert(showAlert);
  };

  function validationTextfields() {
    if (!rutValidator.validate(cliente.rut)) {
      displayAlert(true, "Ingrese un RUT Válido");
    } else if (!validator.validate(cliente.mail)) {
      displayAlert(true, "Ingrese un correo electrónico válido");
    } else if (!schema.validate(cliente.password)) {
      displayAlert(
        true,
        "Ingrese una contraseña válida: Debe contener al menos 6 caracteres, dos números y no debe contener espacios."
      );
    } else if (cliente.password !== confirm_new_password) {
      displayAlert(true, "Las contraseñas ingresadas no coinciden");
    } else if (cliente.nombres === "") {
      displayAlert(true, "Ingrese su nombre");
    } else if (cliente.apellidoPaterno === "") {
      displayAlert(true, "Ingrese su primer apellido");
    } else if (cliente.fechaNacimiento === "") {
      displayAlert(true, "Ingrese su fecha de nacimiento");
    } else if (cliente.fono === "") {
      displayAlert(true, "Ingrese su teléfono");
    } else if (cliente.fono.length !== 15) {
      displayAlert(true, "Su teléfono debe incluir 9 dígitos");
    } else if (cliente.idGenero === 0) {
      displayAlert(true, "Seleccione un género");
    } else if (cliente.direccion === "") {
      displayAlert(true, "Ingrese su dirección");
    } else if (cliente.idComuna === 0) {
      displayAlert(true, "Seleccione su comuna de residencia");
    } else {
      displayAlert(false, " ");
      submitCreateCliente();
    }
  }

  function submitCreateCliente() {
    const cliente_aux = { ...cliente };
    delete cliente_aux.id;
    delete cliente_aux.accessToken;

    const headers = {
      "content-type": "application/json",
      "x-access-token": cliente.accessToken,
    };

    axios
      .put(`${Url}/api/fromPage/cliente/${cliente.id}`, cliente_aux, {
        headers: headers,
      })
      .then(() => {
        history.push("/perfil");
      })
      .catch((error) => {
        console.error("Error:" + JSON.parse(error.request.response).message);
        displayAlert(
          true,
          "Error: " + JSON.parse(error.request.response).message
        );
      });
  }

  return (
    <div>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <div>
              <HeaderPage />
              <div className="flex pl-12">
                <label
                  className="back-icon"
                  style={{ textAlign: "left" }}
                  onClick={() => history.goBack()}
                >
                  ←
                  <label className="mut-text-h2 text-5xl pl-4 lg:pl-20">
                    EDITAR DATOS
                  </label>
                </label>
              </div>
            </div>
          </div>
          <br></br>
          <Form>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn second"
                value="RUT"
                disabled
              />
              <Input
                type="text-registro"
                id="rut"
                className="fadeIn second"
                name="rut"
                placeholder="Ingrese su RUT"
                value={cliente.rut}
                disabled
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn third"
                value="E-mail"
                disabled
              />
              <Input
                type="text-registro"
                id="mail"
                className="fadeIn third"
                name="mail"
                placeholder="ejemplo@mut.cl"
                value={cliente.mail}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn fourth"
                value="Contraseña"
                disabled
              />
              <Input
                type="password-registro"
                id="passwordRegister"
                className="fadeIn fourth placeholder-pink-mut-placeholder"
                name="password"
                placeholder="Contraseña"
                value={cliente.password}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn fifth"
                value="Confirmar contraseña"
                disabled
              />
              <Input
                type="password-registro"
                id="confirm_new_password"
                className="fadeIn fifth placeholder-pink-mut-placeholder"
                name="confirm_new_password"
                placeholder="Ingrese nuevamente su contraseña"
                value={confirm_new_password}
                onChange={handleSetConfirmPasswordChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn sixth"
                value="Nombre"
                disabled
              />
              <Input
                type="text-registro"
                id="nombres"
                className="fadeIn sixth"
                name="nombres"
                placeholder="Ingrese su nombre"
                value={cliente.nombres}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn seventh"
                value="Primer apellido"
                disabled
              />
              <Input
                type="text-registro"
                id="apellidoPaterno"
                className="fadeIn seventh"
                name="apellidoPaterno"
                placeholder="Ingrese su primer apellido"
                value={cliente.apellidoPaterno}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn eigth"
                value="Segundo apellido"
                disabled
              />
              <Input
                type="text-registro"
                id="apellidoPaterno"
                className="fadeIn eigth"
                name="apellidoMaterno"
                placeholder="Ingrese su segundo apellido"
                value={cliente.apellidoMaterno}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn ninth"
                value="Fecha de nacimiento"
                disabled
              />
              <Input
                type="date"
                id="fechaNacimiento"
                className="fadeIn ninth"
                name="fechaNacimiento"
                placeholder="Ingrese su fecha de nacimiento"
                value={cliente.fechaNacimiento}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn tenth"
                value="Teléfono"
                disabled
              />
              <InputMask
                mask="+56 9 9999 9999"
                type="text-registro"
                id="fono"
                className="fadeIn tenth"
                name="fono"
                placeholder="Ingrese su número telefónico"
                value={cliente.fono}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn eleventh"
                value="Género"
                disabled
              />
              <select
                required
                class="inputSelect"
                className="fadeIn eleventh"
                name="idGenero"
                value={cliente.idGenero}
                onChange={handleInputChange}
              >
                <option disabled value={0}>
                  Seleccione su género
                </option>
                <option value={1}>Hombre</option>
                <option value={2}>Mujer</option>
                <option value={3}>Otro</option>
              </select>
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn twelveth"
                value="Dirección"
                disabled
              />
              <Input
                type="text-registro"
                id="direccion"
                className="fadeIn twelveth"
                name="direccion"
                placeholder="Ingrese su dirección"
                value={cliente.direccion}
                onChange={handleInputChange}
              />
            </div>
            <br></br>
            <div class="inputDiv">
              <Input
                type="search-registro"
                className="fadeIn thirteenth"
                value="Comuna"
                disabled
              />
              <select
                required
                class="inputSelect"
                className="fadeIn thirteenth"
                name="idComuna"
                value={cliente.idComuna}
                onChange={handleInputChange}
              >
                {comunas.map((comuna) => (
                  <option value={comuna[0]}>{comuna[1]}</option>
                ))}
              </select>
            </div>
            <br></br>
            <Input
              type="button"
              className="fadeIn fourteenth bg-green-mut mb-10 text-3xl lg:text-5xl"
              value="Guardar Cambios"
              onClick={() => validationTextfields()}
              style={{width: "85%"}}
            />
            {alertMessage && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {alertMessage}
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditarPerfil;
