import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ReactComponent as WebPayLogo } from "../../common/images/web_pay.svg";
import { getCurrentClient } from "../../services/auth.service";
import Url from "../../url";
import "./Planes.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import WebpayPlus from "../../services/WebpayPlus";
import CircularProgress from "@mui/material/CircularProgress";
import HeaderPage from "../HeaderPage/HeaderPage";
import { ReactComponent as PlanIcon } from "../../common/images/plan_icon_red.svg";

import moment from "moment";

const Planes = (prop) => {
  let History = useHistory();
  const [id_plan, setIdPlan] = useState("");
  const [availablesPlans, setAvailablesPlans] = useState([]);
  const [payment_method, setPaymentMethod] = useState("");
  const [cliente, setCliente] = useState(getCurrentClient());
  const [currentSuscripcion, setCurrentSuscripcion] = useState(null);
  const [pressedButton, setPressedButton] = useState(false);
  const [cupon_id, setCuponId] = useState("");
  const WebpayPlusService = WebpayPlus();

  useEffect(() => {
    async function getClientData() {
      const headers = {
        "x-access-token": cliente.accessToken,
      };
      return axios
        .get(`${Url}/api/fromPage/cliente/data/${cliente.id}`, {
          headers: headers,
        })
        .then((response) => {
          const cliente = response.data.cliente;
          //console.log(cliente);
          if (cliente.suscripcion) {
            setCurrentSuscripcion(cliente.suscripcion[0]);
          }
          const tipo_cliente = cliente.tipo_cliente;

          axios
            .get(`${Url}/api/plan/${tipo_cliente}`, { headers: headers })
            .then((response) => {
              // console.log(response.data);
              setAvailablesPlans(response.data);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getClientData();
  }, []);

  const handleSelectedPlan = (event) => {
    setIdPlan(event.target.value);
    //console.log(event.target.value)
  };

  const handleSelectedPaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    //console.log(event.target.value)
  };

  function redirectToWebpay(data) {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = data.webpay_data.url;
    const tokenInput = document.createElement("input");
    tokenInput.type = "hidden";
    tokenInput.name = "token_ws";
    tokenInput.value = data.webpay_data.token;
    form.appendChild(tokenInput);
    // Append the form to the document
    document.body.appendChild(form);
    // Submit the form to redirect
    form.submit();
  }

  function chargeAmount() {
    if (id_plan.length === 0) {
      alert("Seleccione un plan");
    } else {
      //console.log(id_plan);
      setPressedButton(true);
      const selected_plan_obj = availablesPlans.find(
        (item) => item.id === parseInt(id_plan)
      );

      //console.log(selected_plan_obj);
      WebpayPlusService.createTransactionPlan(
        "plan",
        cliente,
        selected_plan_obj,
        cupon_id
      )
        .then((response) => {
          console.log(response.data);
          redirectToWebpay(response.data);
        })
        .catch((error) => {
          setPressedButton(true);
          console.log(error);
        });
    }
  }

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <HeaderPage />
          <div className="flex pl-12">
            <label
              className="back-icon"
              style={{ textAlign: "left" }}
              onClick={() => History.goBack()}
            >
              ←<label className="mut-text-h2 text-5xl pl-16 lg:pl-36">PLANES</label>
            </label>
          </div>
          <div>
            <PlanIcon className="icon-inside-card pb-20 w-80 mt-5" />
          </div>
          <div className="pl-12" style={{ textAlign: "left" }}>
            <div>
              <label className="mut-text-h2 text-3xl lg:text-5xl pb-5">Plan Actual</label>
            </div>
            <div style={{ marginLeft: "20px" }}>
              {currentSuscripcion ? (
                <div className="mb-10">
                  <label className="mut-text-h5 text-green-mut text-2xl lg:text-3xl font-bold">
                    {" "}
                    {`${currentSuscripcion.plan.nombre} ( Activo hasta ${moment(
                      currentSuscripcion.fechaFin
                    ).format("DD-MM-YYYY")} ) `}
                  </label>
                  <br></br>
                  <label className="text-2xl lg:text-3xl font-thin">
                    {currentSuscripcion.plan.descripcion.split(">")[1]}
                  </label>
                </div>
              ) : (
                <label className="mut-text-h5 text-black text-2xl lg:text-3xl pb-10">
                  No esta suscrito a ningun plan actualmente
                </label>
              )}
            </div>
            <div>
              <label
                style={{ marginBottom: "-10px" }}
                className="mut-text-h2 text-3xl lg:text-5xl pb-5"
              >
                Seleccionar Nuevo Plan
              </label>
            </div>
            <div>
              <div className="div-form-radio-button">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleSelectedPlan}
                  >
                    {availablesPlans.map((plan) => {
                      return (
                        <div className="card-radio-button pb-10">
                          <FormControlLabel
                            value={plan.id}
                            control={
                              <Radio
                                size="large"
                                sx={{
                                  color: "#375480",
                                  "&.Mui-checked": {
                                    color: "#375480",
                                  },
                                }}
                              />
                            }
                            label={
                              <div className="mt-9 font-neu_reg">
                                <label className="text-red-mut text-2xl lg:text-4xl">
                                  {plan.nombre}{" "}
                                </label>
                                <br />
                                <label className="text-xl lg:text-2xl font-thin">
                                  {plan.descripcion.split(">")[0]}

                                  <br></br>

                                  {plan.descripcion.split(">")[1]}
                                </label>
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <label className="mut-text-h2 text-3xl lg:text-5xl pb-3 mt-20">
                  Medio de pago
                </label>
              </div>
              <div className="div-form-radio-button">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="webpayplus"
                    name="radio-buttons-group"
                    onChange={handleSelectedPaymentMethod}
                  >
                    <div className="card-radio-button">
                      <FormControlLabel
                        value="webpayplus"
                        control={
                          <Radio
                            size="large"
                            sx={{
                              color: "#375480",
                              "&.Mui-checked": {
                                color: "#375480",
                              },
                            }}
                          />
                        }
                        label={
                          <WebPayLogo className="webpay-log-form-control" />
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="pb-20 pt-20">
                <button
                  type="button-wallet"
                  className="fadeIn third capitalize bg-green-mut text-3xl lg:text-5xl"
                  onClick={() => chargeAmount()}
                  disabled={pressedButton}
                >
                  {pressedButton ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Pagar"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planes;
