// Constantes usadas en todo el Codigo

const constantes = {
  tipos: ["Bicicleta", "Scotter Electrico", "Otro"],
  roles: ["user", "moderator", "admin"],
  page_sizes: [25, 50, 100],
};

const comunas = [
  [1, "Cerrillos"],
  [2, "Cerro Navia"],
  [3, "Conchalí"],
  [4, "El Bosque"],
  [5, "Estación Central"],
  [6, "Huechuraba"],
  [7, "Independencia"],
  [8, "La Cisterna"],
  [9, "La Florida"],
  [10, "La Granja"],
  [11, "La Pintana"],
  [12, "La Reina"],
  [13, "Las Condes"],
  [14, "Lo Barnechea"],
  [15, "Lo Espejo"],
  [16, "Lo Prado"],
  [17, "Macul"],
  [18, "Maipú"],
  [19, "Ñuñoa"],
  [20, "Pedro Aguirre Cerda"],
  [21, "Peñalolén"],
  [22, "Providencia"],
  [23, "Pudahuel"],
  [24, "Quilicura"],
  [25, "Quinta Normal"],
  [26, "Recoleta"],
  [27, "Renca"],
  [28, "Santiago"],
  [29, "San Joaquín"],
  [30, "San Miguel"],
  [31, "San Ramón"],
  [32, "Vitacura"],
  [33, "Puente Alto"],
  [34, "Pirque"],
  [35, "San José de Maipo"],
  [36, "Colina"],
  [37, "Lampa"],
  [38, "Tiltil"],
  [39, "San Bernardo"],
  [40, "Buin"],
  [41, "Calera de Tango"],
  [42, "Paine"],
  [43, "Melipilla"],
  [44, "Alhué"],
  [45, "Curacaví"],
  [46, "María Pinto"],
  [47, "San Pedro"],
  [48, "Talagante"],
  [49, "El Monte"],
  [50, "Isla de Maipo"],
  [51, "Padre Hurtado"],
  [52, "Peñaflor"],
];

export default {constantes,comunas};

