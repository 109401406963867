import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as BikeIcon } from "../../common/images/bike_icon.svg";
import "./Bicicleta.css";
import Url from "../../url";
import { getCurrentClient } from "../../services/auth.service";
import axios from "axios";
import HeaderPage from "../HeaderPage/HeaderPage";
import gatoMut from "../../common/images/gato_mut.png";

const Bicicleta = (props) => {
  let History = useHistory();
  const [vehiculos, setVehiculos] = useState([]);

  useEffect(() => {
    const cliente = getCurrentClient();
    function getVehiculosByCliente() {
      const headers = {
        "x-access-token": cliente.accessToken,
      };
      axios
        .get(`${Url}/api/fromPage/cliente/findVehiculos/${cliente.id}`, {
          headers: headers,
        })
        .then((response) => {
          const allVehiculos = response.data;
          const aux = [];
          allVehiculos.forEach((vehiculo) => {
            aux.push({
              aro: vehiculo.aro,
              marca: vehiculo.marca,
              color: vehiculo.color,
              descripcion: vehiculo.descripcion,
            });
          });
          setVehiculos(aux);
        })
        .catch((error) => console.error("Error: " + error));
    }
    getVehiculosByCliente();
  }, []);

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <HeaderPage />
          <div className="flex pl-12 pb-20">
            <label
              className="back-icon"
              style={{ textAlign: "left" }}
              onClick={() => History.goBack()}
            >
              ←
            </label>
          </div>

          {vehiculos.map((vehiculo) => {
            return (
              <div className="bici-row-cards">
                <div className="bici-single-card">
                  <BikeIcon
                    className="icon-bicicleta"
                    style={{ width: "100%" }}
                  />
                  <div className="grid grid-rows-1 justify-items-center gap-4 pt-12">
                    <label className="mut-text-h2 text-5xl">
                      {vehiculo.marca} {vehiculo.descripcion}
                    </label>
                    <label className="mut-text-h4 text-black text-3xl">
                      Color: {vehiculo.color}
                    </label>
                    <label className="mut-text-h4 text-black text-3xl">
                      Aro: {vehiculo.aro}
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mt-24 pt-40 lg:pt-20">
            <img src={gatoMut} style={{ marginBottom: "-18rem" }} />
          </div>
          <div>
            <label
              className="mut-text-h2 text-3xl lg:text-4xl pl-12 pr-32 lg:pr-56"
              style={{ textAlign: "left" }}
            >
              Lo curioso del ciclismo es que tú eres el motor y a la vez el
              conductor.
            </label>
          </div>
          <div className="mb-40"> </div>
        </div>
      </div>
    </div>
  );
};

export default Bicicleta;
